import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Home, Downloads, Represents, NotFound, Training, Truck, News, Career, Impressum } from "./views";



function App() {


  return (
    <Router>
      <Switch>
        <Redirect exact path="/" to="/home" />
        <Route path="/home">
          <Home></Home>
        </Route>
        <Route path="/represents">
          <Represents></Represents>
        </Route>
        <Route path="/downloads">
          <Downloads></Downloads>
        </Route>
        <Route path="/not-found">
          <NotFound></NotFound>
        </Route>
        <Route path="/training">
          <Training></Training>
        </Route>
        <Route path="/information-truck">
          <Truck></Truck>
        </Route>
        <Route path="/news">
          <News></News>
        </Route>
        <Route path="/career">
          <Career></Career>
        </Route>
        <Route path="/imprint">
          <Impressum></Impressum>
        </Route>
        <Redirect to="/not-found" status="404" />
      </Switch>
    </Router>
  );
}

export default App;
