import { makeStyles } from "@material-ui/core"


const useStyles = makeStyles(theme => ({
  root: {
    borderRight: `solid ${theme.palette.text.primary}`,
    borderBottom: `solid ${theme.palette.text.primary}`,

  }
}))

export default function Triangle({ size = 50, rotation = -45, width = 3, ...rest }) {

  const classes = useStyles()

  return (
    <div
      className={classes.root}
      style={{
        height: size,
        width: size,
        borderBottomRightRadius: size * 0.17,
        transform: `rotate(${rotation}deg)`,
        borderRightWidth: `${width}px`,
        borderBottomWidth: `${width}px`,
      }}
    ></div>
  );
}