const palette = {
  "text": {
    "primary": "rgba(255, 255, 255, 1)",
    "secondary": "rgba(255, 255, 255, 0.8)"
  },
  "background": {
    "default": "#0d0d0d",
    "footer": "#0d0d0d",
    "input": "rgba(255, 255, 255, 0.2)",
    "light": "rgba(255, 255, 255, 0.1)",
    "linkBackground": "rgba(0, 0, 0, 0.4)",
    "nav": "linear-gradient(-40deg, rgba(13,13,13,.6) 0%, rgba(45,45,45,.6) 80%)",
  }
}

export default palette