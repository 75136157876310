import { makeStyles } from "@material-ui/core";
import theme from "../../../theme";

const useStyles = makeStyles(theme => ({
  shiftContainer: {
    height: "10px",
    display: "flex",
    flexFlow: "row nowrap",

  },
  shiftPill: {
    width: "40px",
    height: "100%",
    borderRadius: "5px",
    '+$shiftPill': {
      transform: "translateX(-20%)"
    }
  }
}))

export default function ColorShift({variant}) {

  const classes = useStyles()
  let newColorArray = []

  const genNewColorArray = (colorArray) => {
    const lastColor = colorArray.pop();
    newColorArray = [lastColor, ...colorArray]

  }

  if (variant) {
    let colorArray = Object.entries(theme.palette.secondary).map(([key, val]) => ([key, val]));
    genNewColorArray(colorArray)
  } else {
    let colorArray = Object.entries(theme.palette.primary).map(([key, val]) => ([key, val]));
    genNewColorArray(colorArray)
  }

  return(
    <div className={classes.shiftContainer}>
      {newColorArray.map(([key, value], idx) => (
        <div 
          className={classes.shiftPill} 
          key={key} 
          style={{backgroundColor: value, transform: `translateX(${-10*idx}px)`}}
        ></div>
      ))}
      
    </div>
  )
}