import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./index.css"

import { ThemeProvider, CssBaseline } from "@material-ui/core";
import theme from "./theme/index";

//Import all Fonts
import './fonts/montserrat-v15-latin-regular.eot';
import './fonts/montserrat-v15-latin-regular.woff2';
import './fonts/montserrat-v15-latin-regular.woff';
import './fonts/montserrat-v15-latin-regular.ttf';
import './fonts/montserrat-v15-latin-regular.svg';

import './fonts/montserrat-v15-latin-700.eot';
import './fonts/montserrat-v15-latin-700.woff2';
import './fonts/montserrat-v15-latin-700.woff';
import './fonts/montserrat-v15-latin-700.ttf';
import './fonts/montserrat-v15-latin-700.svg';

import './fonts/montserrat-v15-latin-300.eot';
import './fonts/montserrat-v15-latin-300.woff2';
import './fonts/montserrat-v15-latin-300.woff';
import './fonts/montserrat-v15-latin-300.ttf';
import './fonts/montserrat-v15-latin-300.svg';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

const client = new ApolloClient({
  uri: 'https://api-eu-central-1.graphcms.com/v2/ckpy0gneh5i3e01z0e890bfkt/master',
  cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </ApolloProvider>
      
  </React.StrictMode>,
  document.getElementById('root')
);

