import logoImg from "./logo.svg";
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "block"
  }
}))

export default function Logo() {
  const classes = useStyles()
  return (
    <img src={logoImg} alt="Logo der Lorenz Industrievertretungen GmbH" className={classes.root}></img>
  )
}