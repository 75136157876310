import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

import palette from "./palette";

const theme = responsiveFontSizes(
  createMuiTheme({
    palette,
    layout: {
      contentWidth: 1200
    },
    typography: {
      fontFamily: "Montserrat, Arial"
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          "body": {
            "background": "linear-gradient(140deg, rgba(13,13,13,1) 0%, rgba(45,45,45,1) 80%)",
            "background-color": "rgba(13,13,13,1)",
            "min-height": "100vh",
          },
          "MuiInputBase": {
            "borderRadius": 0
          },
          html: {
            "scroll-behavior": "smooth"
          }
        },
      },
    },
  })
)

export default theme