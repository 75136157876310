import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import { Tween } from 'react-gsap';
import gsap from "gsap"
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.input,
    width: theme.spacing(2),
    height: 120,
    borderRadius: 30,
    position: "sticky",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    padding: theme.spacing(.5),
    zIndex: 1100
  },
  pill: {
    backgroundColor: theme.palette.secondary.light,
    width: "100%",
    height: 30,
    borderRadius: 30,
  }
}));


export default function Scroller() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tween
        to={{
          y: 82,
          scrollTrigger: {
            scrub: 0.2
          }
        }}
      >
        <div className={clsx(classes.pill, "ani-pill")}></div>
      </Tween>

    </div>

  )
}