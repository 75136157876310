import { Nav, Footer, Section, NothingHere } from "../../components/organism";
import { Scroller } from "../../components/atoms";
import { LinearProgress, Hidden } from "@material-ui/core"

import { useQuery, gql } from "@apollo/client";

export default function Represents() {

  const sectionIds = gql`
    query sectionIds {
      categories(where: {categorie: "represents"}) {
        sections {
          id
          title
        }
      }
    }  
  `

  const { loading, error, data } = useQuery(sectionIds);

  return (
    <>
      <Nav></Nav>
      {loading && <LinearProgress />}
      {error && <div>{error.stack}</div>}
      {data && data.categories[0].sections.map((section, idx) => (<Section key={idx} queryId={section.id}></Section>))}
      {data && <NothingHere data={data.categories[0]}></NothingHere>}
      <Hidden smDown>
        <Scroller></Scroller>
      </Hidden>
      <Footer></Footer>
    </>
  )
}