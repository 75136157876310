import {CustomInput, CustomButton} from "../../atoms";
import {useState} from "react";

import {Box, makeStyles, CircularProgress, Typography} from "@material-ui/core";

import { send, init } from "emailjs-com";
init("user_7VVWVreDExXPoqiQmmCJR");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(2)
  },
  input: {
    margin: theme.spacing(1, 0)
  },
  formElement: {
    margin: theme.spacing(0, 0, 1, 0)
  },
  loadingContainer: {
    marginRight: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  formSent: {
    color: theme.palette.success.light
  }
}))

export default function ContactForm() {

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [issue, setIssue] = useState("");
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState(false);
  const [formSent, setFormSent] = useState(false)

  async function submitForm(e) {
    e.preventDefault();
    setLoading(true);
    const mailData = {"from_name": name, "reply_to": mail, "message": issue};
    setFormError(false);
    setFormSent(false);

    try {
      await send("lorenzind_mails", "lorenzind_template", mailData, "user_7VVWVreDExXPoqiQmmCJR");
      setLoading(false)
      setFormSent(true)
    } catch(err) {
      setLoading(false);
      console.log(err);
      setFormError(true);
    }
    //Mail senden!!
    
  }

  const classes = useStyles()
  return(
    <form className={classes.root} id="contact">
      <Box className={classes.formElement}>
        <CustomInput
          value={name} 
          setValue={setName} 
          type="text" 
          autoComplete="name" 
          required 
          id="name" 
          placeholder="Name"
        ></CustomInput>
      </Box>
      <Box className={classes.formElement}>
        <CustomInput 
          value={mail} 
          setValue={setMail} 
          type="e-mail" 
          autoComplete="username" 
          required 
          id="e-mail" 
          placeholder="E-Mail"
        ></CustomInput>
      </Box>
      <Box className={classes.formElement}>
        <CustomInput 
          value={issue} 
          setValue={setIssue} 
          required 
          id="issue" 
          placeholder="Anliegen" 
          multiline
        ></CustomInput>
      </Box>
      <Box className={classes.buttonContainer}>
        <Box className={classes.loadingContainer}>
          {loading && <CircularProgress color="secondary" size={25} />}
          {formError && <Typography color="secondary">Es ist ein Fehler aufgetreten.</Typography>}
          {formSent && <Typography className={classes.formSent}>Anfrage versandt.</Typography>}
        </Box>
        <CustomButton 
          disabled={loading}
          title="Abschicken" 
          submitForm={submitForm}
          onClick={(e) => submitForm(e)} 
          variant
        ></CustomButton>
      </Box>
    </form>
    
  )
}