import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
  },
  inputEl: {
    borderRadius: 0,
    padding: theme.spacing(1,3),
  },
  input: {
    border: "none",
    backgroundColor: theme.palette.background.input,
    borderRadius: "15px",
    overflow: "hidden",
    padding: 0,
    fontSize: 14
  },
  label: {
    marginLeft: theme.spacing(3),
    fontSize: 14
  },
  labelShrink: {
    transform: 'translate(0, -3px) scale(0.75)'
  }
}))
export default function CustomInput({required, id, placeholder, multiline, value, setValue, type, autoComplete, ...rest}) {
  const classes = useStyles()

  return(  
    <TextField
      fullWidth
      type={type}
      autoComplete={autoComplete}
      className={classes.root} 
      required={required} 
      id={id} 
      multiline={multiline}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      classes={{root: classes.root}}
      label={placeholder}
      inputProps={{className: classes.inputEl}}
      InputProps={{className: classes.input}}
      InputLabelProps={{className: classes.label}}
      color={"secondary"}
      variant="standard"
      rows={4}
    >
    </TextField>
  );
}