import { makeStyles, Container, Box, Typography, Grid, Hidden } from "@material-ui/core";
import { TrippleArrow, Slideshow, World, Logo } from "../../../components/atoms";
import { Timeline, Tween } from "react-gsap";
import clsx from "clsx";


import { HashLink } from "react-router-hash-link"
import { forwardRef, useRef, useImperativeHandle } from "react";




const useStyles = makeStyles(theme => ({
  landingContainer: {
    height: "100vh",
    position: "relative"
  },
  arrowContainer: {
    position: "absolute",
    bottom: theme.spacing(1),
    left: "50%",
    transform: "translateX(-50%)"
  },
  fullHeight: {
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
  },
  gridHeight40: {
    height: "40%"
  },
  gridHeight30: {
    height: "30%"
  },
  curtain: {
    position: "absolute",
    backgroundColor: "rgba(42, 42, 42, 1)",
    height: "50%",
    left: 0,
    right: 0,
    zIndex: 4000,
    display: "none"
  },
  curtain1: {
    top: 0,
  },
  curtain2: {
    top: "50%"
  },
  worldContainer: {
    position: "absolute",
    zIndex: -1,
    opacity: 0.4
  },
  worldConDesk: {
    bottom: "5%",
    left: "5%",
  },
  worldConMobile: {
    bottom: "auto",
    top: "10px",
    left: "auto",
    right: "0"
  },
  paddingBot: {
    paddingBottom: theme.spacing(2)
  }
}))

export default function Landing() {

  function SlideshowWrapper({ size = "med" }) {

    return (
      <Grid classes={{ root: classes.gridHeight40 }} container direction="row" alignItems="stretch" justify="flex-end">
        <Grid item xs={12} sm={8} md={6} lg={5}>
          <Hidden smDown>
            <Slideshow></Slideshow>
          </Hidden>
          <Hidden mdUp>
            <Slideshow indicatorSize={10}></Slideshow>
          </Hidden>

        </Grid>
      </Grid>
    )
  }

  const TargetWithNames = forwardRef((props, ref) => {
    const div1 = useRef(null);
    const div2 = useRef(null);
    const div3 = useRef(null);
    useImperativeHandle(ref, () => ({
      div1,
      div2,
      div3,
    }));

    return (
      <>
        <div ref={div1} className={clsx(classes.curtain, classes.curtain1)}></div>
        <div ref={div2} className={clsx(classes.curtain, classes.curtain2)}></div>
        <Hidden mdDown>
          <Box aria-hidden className={clsx(classes.worldContainer, classes.worldConDesk)}>
            <World size={800}></World>
          </Box>
        </Hidden>
        <Hidden only={["xs", "lg", "xl"]}>
          <Box aria-hidden className={clsx(classes.worldContainer, classes.worldConDesk)}>
            <World size={600}></World>
          </Box>
        </Hidden>
        <Hidden smUp>
          <Box aria-hidden className={clsx(classes.worldContainer, classes.worldConMobile)}>
            <World size={300}></World>
          </Box>
        </Hidden>

        <Container classes={{ root: classes.fullHeight }}>
          <Grid container classes={{ root: classes.paddingBot }}>
            <Grid item xs={12} sm={10} md={9} lg={8}>
              <Logo></Logo>
            </Grid>
          </Grid>


          <SlideshowWrapper></SlideshowWrapper>

          <Timeline
            target={
              <Box className={classes.arrowContainer}>
                <HashLink to="#ueber-uns">
                  <Hidden smDown><TrippleArrow></TrippleArrow></Hidden>
                  <Hidden mdUp><TrippleArrow size={30} width={2}></TrippleArrow></Hidden>
                </HashLink>
              </Box>
            }
            repeat={-1}
            repeatDelay={5}
          >
            <Tween to={{ y: 15 }} duration={.1}></Tween>
            <Tween to={{ y: 0 }} duration={.2}></Tween>
            <Tween to={{ y: 15 }} duration={.1}></Tween>
            <Tween to={{ y: 0 }} duration={.2}></Tween>
            <Tween to={{ y: 15 }} duration={.1}></Tween>
            <Tween to={{ y: 0 }} duration={.2}></Tween>
          </Timeline>

        </Container>
      </>

    )
  })

  const classes = useStyles()

  return (
    <Box component="section" className={classes.landingContainer} >
      <Timeline target={<TargetWithNames />}>
        <Tween to={{ x: -100, display: "none" }} target="div1" position="0"></Tween>
        <Tween to={{ x: "100vw" }} target="div2" position="1"></Tween>
        <Tween from={{ x: "100vw" }} target="div3" position="1"></Tween>
      </Timeline>
    </Box>
  )
}