import { Fade } from "react-slideshow-image";
import { Box, Hidden } from "@material-ui/core";
import { LinkButton } from "../../molecules"


import 'react-slideshow-image/dist/styles.css';

import { makeStyles } from "@material-ui/core";

import { useQuery, gql } from "@apollo/client";

const SLIDE_QUERY = gql`
  query slideQuery {
    sections(where: {categorie: {categorie: "represents"}}) {
      image {
        imageAltText
        url
      }
      linkHref
      linkSubtitle
      linkTitle
    }
  }
`


const useStyles = makeStyles(theme => ({
  wrapper: {
    height: "100%",
    width: "100%",
    borderRadius: 30
  },
  slide: {
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: 30,
    overflow: "hidden"
  },
  indicator: {
    borderRadius: "50%",
    backgroundColor: theme.palette.background.input,
    margin: theme.spacing(0, 1),
    transition: "background .7s ease",
    "&.active": {
      backgroundColor: theme.palette.secondary.light
    }
  },
  linkBackground: {
    width: "100%",
    background: theme.palette.background.linkBackground,
    backdropFilter: "blur(2px)",
    padding: theme.spacing(2)
  }
}))

export default function Slideshow({ indicatorSize = 15 }) {

  const classes = useStyles()

  const { data, loading, error } = useQuery(SLIDE_QUERY)

  function Indicator() {
    return (
      <div className={classes.indicator} style={{ width: indicatorSize, height: indicatorSize }}></div>

    );
  }

  function Slide({ data, idx }) {
    return (
      <div className="each-slide">
        <div className={classes.slide} style={{ 'backgroundImage': `url(${data.image.url})` }}>
          <Box width="100%" height="100%" display="flex" alignItems="flex-end">
            <Box classes={{ root: classes.linkBackground }}>
              <Hidden mdUp>
                <LinkButton title={data.linkTitle} href={data.linkHref} subtitle={data.linkSubtitle} buttonSize={15} buttonWidth={2}></LinkButton>
              </Hidden>
              <Hidden smDown>
                <LinkButton title={data.linkTitle} href={data.linkHref} subtitle={data.linkSubtitle} buttonSize={25} buttonWidth={3}></LinkButton>
              </Hidden>
            </Box>
          </Box>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      {loading ? null :
        <Fade arrows={false} indicators={idx => Indicator(idx)} easing="ease">
          {data.sections.map((section, idx) => <Slide key={idx} idx={idx} data={section}></Slide>)}
        </Fade>
      }
    </div>
  )
}