import {Grid, Container, makeStyles, Typography, Link as MaterialLink, Box} from "@material-ui/core";
import {Link} from "react-router-dom";
import { ContactForm } from "../../molecules";
import { Logo } from "../../atoms";


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.footer,
    width: "100%",
    marginTop: theme.spacing(3)
  },
  header: {
    height: 100,
    display: "flex",
    alignItems: "flex-end",
    justifyContent:"flex-start"
  },
  spaceOut: {
    padding: theme.spacing(1, 0)
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  extraSpacing: {
    padding: theme.spacing(2.5, 0, 1, 0)
  },
  heading: {
    lineHeight: 1
  },
  containerMargin: {
    paddingBottom: 60
  },
  logoWrapper: {
    width: "80%",
    display: "flex",
    justifyContent: "flex-end"
  }
}))

export default function Footer() {

  const classes = useStyles()

  return(
    <footer className={classes.root}>
      <Container classes={{root: classes.containerMargin}}>
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container>
              <Grid item classes={{root: classes.header}}>
                <Box className={classes.logoWrapper}>
                  <Logo></Logo>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container>
              <Grid classes={{root: classes.header}} item container alignContent="flex-end">
                <Typography variant="subtitle2" component="h6">Kontakt</Typography>
              </Grid>
              <Grid classes={{root: classes.extraSpacing}} item xs={12}>
                <MaterialLink href="https://goo.gl/maps/kidbnKR2hVvivp6J9" rel="noreferrer" color="inherit">
                  <Typography variant="body2">Göppersdorfer Straße 82</Typography>
                  <Typography variant="body2">09217 Burgstädt</Typography>
                </MaterialLink>
                
              </Grid>
              <Grid classes={{root: classes.spaceOut}} item xs={12}>
                <Typography variant="body2">
                  <MaterialLink href="tel:+4937246698855" color="inherit">
                    03724 6698855
                  </MaterialLink>
                </Typography>
                <Typography variant="body2">
                  <MaterialLink href="mailto:info@lorenzind.com" color="inherit">info@lorenzind.com</MaterialLink>
                </Typography>
              </Grid>
              <Grid classes={{root: classes.spaceOut}} item xs={12}>
                <Typography variant="body2">Bürozeiten:</Typography>
                <Typography variant="body2">Mo-Do 9:00-16:00</Typography>
                <Typography variant="body2">Fr 9:00-14:00</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Grid container>
              <Grid classes={{root: classes.header}} item container alignContent="flex-end">
                <Typography variant="subtitle2" component="h6">Links</Typography>
              </Grid>
              <Grid item xs={12} classes={{root: classes.extraSpacing}}>
                <Typography variant="body2">
                  <Link className={classes.link} to="/represents">Vertretungen</Link>
                </Typography>
              </Grid>
              <Grid item xs={12} classes={{root: classes.spaceOut}}>
                <Typography variant="body2">
                  <Link className={classes.link} to="/downloads">Downloads</Link>
                </Typography>
              </Grid>
              <Grid item xs={12} classes={{root: classes.spaceOut}}>
                <Typography variant="body2">
                  <Link className={classes.link} to="/training">Schulungen</Link>
                </Typography>
              </Grid>
              <Grid item xs={12} classes={{root: classes.spaceOut}}>
                <Typography variant="body2">
                  <Link className={classes.link} to="/information-truck">Info-Mobil</Link>
                </Typography>
              </Grid>
              <Grid item xs={12} classes={{root: classes.spaceOut}}>
                <Typography variant="body2">
                  <Link className={classes.link} to="/news">Aktuelles</Link>
                </Typography>
              </Grid>
              <Grid item xs={12} classes={{root: classes.spaceOut}}>
                <Typography variant="body2">
                  <Link className={classes.link} to="/imprint">Impressum</Link>
                </Typography>
              </Grid>
              <Grid item xs={12} classes={{root: classes.spaceOut}}>

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container>
              <Grid classes={{root: classes.header}} item container alignContent="flex-end">
                <Typography variant="subtitle2" component="h6" >Kontaktformular</Typography>
              </Grid>
              <Grid item xs={12} classes={{root: classes.spaceOut}}>
                <ContactForm></ContactForm>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
    
  )
}