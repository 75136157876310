import {makeStyles, Button} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    border: `2px solid ${theme.palette.text.primary}`,
    borderRadius: "30px",
    padding: theme.spacing(0, 5),
    textTransform: "none",
    height: "34px"
  },
  variant: {
    border: "none",
    backgroundColor: theme.palette.background.input
  }
}))

export default function CustomButton({href, title, variant, submitForm, disabled, onClick, ...rest}) {

  const classes = useStyles()
  return(
    <Button
      disabled={disabled}
      className={clsx(classes.root, variant && classes.variant)}
      href={href}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}