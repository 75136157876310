import { Container, Box, Typography, Grid, makeStyles, Hidden, Button, Menu, MenuItem, Divider } from "@material-ui/core";
import { CustomButton, Logo } from "../../atoms";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Menu as MenuIcon } from "@material-ui/icons";
import { useState } from "react"

const useStyles = makeStyles(theme => ({
  link: {
    color: "inherit",
    textDecoration: "none"
  },
  shiftRight: {
    marginLeft: "auto"
  },
  spacingLeft: {
    marginLeft: 20
  },
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: "sticky",
    zIndex: 1000,
    background: theme.palette.background.nav,
    backdropFilter: "blur(10px)",
    top: 0
  },
  logoWrapper: {
    maxWidth: "23%"
  },
  logoWrapperSm: {
    maxWidth: "40%"
  },
  logoWrapperXs: {
    maxWidth: "60%"
  },
  menuPaper: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 20
  },
  menuRoot: {
    zIndex: 2000
  },
  selected: {
    color: theme.palette.secondary.light
  }
}))

export default function Nav() {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const classes = useStyles()
  return (
    <Box component="div" className={classes.root}>
      <Container>
        <Grid container direction="row" alignItems="center" wrap="nowrap">
          {/*Logo und seine angepassten Spannweiten */}
          <Hidden smDown>
            <Grid item className={classes.logoWrapper}>
              <NavLink to="/home">
                <Logo></Logo>
              </NavLink>
            </Grid>
          </Hidden>
          <Hidden mdUp xsDown>
            <Grid item className={classes.logoWrapperSm}>
              <NavLink to="/home">
                <Logo></Logo>
              </NavLink>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item className={classes.logoWrapperXs}>
              <NavLink to="/home">
                <Logo></Logo>
              </NavLink>
            </Grid>
          </Hidden>

          {/*Navigation. Schaltet um auf Burger bei kleinen Bildschirmen*/}
          <Hidden smDown>
            <Grid item classes={{ root: classes.shiftRight }}>
              <Grid container direction="row" alignItems="center">
                <Grid item >
                  <Typography variant="subtitle2" component="p">
                    <NavLink className={classes.link} activeClassName={classes.selected} to="/home">
                      <HashLink className={classes.link} to="/home#ueber-uns">Über uns</HashLink>
                    </NavLink>
                  </Typography>
                </Grid>
                <Grid item classes={{ root: classes.spacingLeft }}>
                  <Typography variant="subtitle2" component="p">
                    <NavLink activeClassName={classes.selected} className={classes.link} to="/represents">Vertretungen</NavLink>
                  </Typography>
                </Grid>
                <Grid item classes={{ root: classes.spacingLeft }}>
                  <Typography variant="subtitle2" component="p">
                    <NavLink activeClassName={classes.selected} className={classes.link} to="/downloads">Downloads</NavLink>
                  </Typography>
                </Grid>
                <Grid item classes={{ root: classes.spacingLeft }}>
                  <Typography variant="subtitle2" component="p">
                    <NavLink activeClassName={classes.selected} className={classes.link} to="/training">Schulungen</NavLink>
                  </Typography>
                </Grid>
                <Grid item classes={{ root: classes.spacingLeft }}>
                  <Typography variant="subtitle2" component="p">
                    <NavLink activeClassName={classes.selected} className={classes.link} to="/information-truck">Info-Mobil</NavLink>
                  </Typography>
                </Grid>
                <Grid item classes={{ root: classes.spacingLeft }}>
                  <Typography variant="subtitle2" component="p">
                    <NavLink activeClassName={classes.selected} className={classes.link} to="/news">Aktuelles</NavLink>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item classes={{ root: classes.spacingLeft }}>
              <CustomButton href="#contact" title="Kontakt"></CustomButton>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item classes={{ root: classes.shiftRight }}>
              <Box>
                <Button onClick={(e) => handleClick(e)}>
                  <MenuIcon></MenuIcon>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  classes={{ paper: classes.menuPaper, root: classes.menuRoot }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <Typography variant="subtitle2" component="p">
                      <NavLink className={classes.link} activeClassName={classes.selected} to="/home">
                        <HashLink className={classes.link} to="/home#ueber-uns">Über uns</HashLink>
                      </NavLink>
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleClose}>
                    <Typography variant="subtitle2" component="p">
                      <NavLink activeClassName={classes.selected} className={classes.link} to="/represents">Vertretungen</NavLink>
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleClose}>
                    <Typography variant="subtitle2" component="p">
                      <NavLink activeClassName={classes.selected} className={classes.link} to="/downloads">Downloads</NavLink>
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleClose}>
                    <Typography variant="subtitle2" component="p">
                      <NavLink activeClassName={classes.selected} className={classes.link} to="/training">Schulungen</NavLink>
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleClose}>
                    <Typography variant="subtitle2" component="p">
                      <NavLink activeClassName={classes.selected} className={classes.link} to="/information-truck">Info-Mobil</NavLink>
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleClose}>
                    <Typography variant="subtitle2" component="p">
                      <NavLink activeClassName={classes.selected} className={classes.link} to="/news">Aktuelles</NavLink>
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <CustomButton href="#contact" title="Kontakt"></CustomButton>
                  </MenuItem>
                </Menu>
              </Box>


            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Box>
  )
}