import { Box, Typography, Grid, Container, makeStyles, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab"
import { LinkButton } from "../../molecules";
import { ColorShift } from "../../atoms";



import { useQuery, gql } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    minHeight: 300
  },
  image: {
    display: "block",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center center"
  },
  section: {
    margin: theme.spacing(9, "auto"),
    backgroundColor: theme.palette.background.light,
    borderRadius: 30,
    overflow: "hidden",
  },
  shiftContainer: {
    margin: theme.spacing(2, 0)
  },
  linkContainer: {
    marginTop: "auto",
    paddingTop: theme.spacing(2)
  },
  imgSkeleton: {
    height: "100%",
    transform: "scale(1)"
  },
  linkMargin: {
    marginBottom: theme.spacing(2)
  }
}))

export default function Section({ queryId, context, ...rest }) {

  const classes = useStyles();

  const SECTION = gql`
    query SECTION($id: ID) {
      section(where: {id: $id}) {
        description
        linkHref
        linkSubtitle
        linkTitle
        image {
          url
          imageAltText
        }
        pageId
        title
        colorShiftVariant
        downloadLink {
          title
          linkHref
        }
        videoLinks {
          videoTitle
          videoDescription
          video {
            url
            id
          }
        }
      }
    }
  `

  const { data, loading } = useQuery(SECTION, { variables: { "id": queryId } })


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"))
  const minHeightVar = matches ? 300 : 0

  function InnerContent({ data }) {
    if (context === "downloads") {
      return (
        <>
          {loading ?
            <Skeleton
              animation="wave"
              variant="text"
              height={150} /> :
            data.section.downloadLink.map((downloadLink, idx) =>
              <Box className={classes.linkMargin}>
                <Hidden smDown> {/* Renders normal-sized Link-Button Component on bigger Screens */}
                  <LinkButton key={idx} title={downloadLink.title} href={downloadLink.linkHref} buttonSize={25} buttonWidth={3}></LinkButton>
                </Hidden>

                <Hidden mdUp> {/* Renders small-sized Link-Button Component on smaller mobile Screens */}
                  <LinkButton key={idx} title={downloadLink.title} href={downloadLink.linkHref} buttonSize={15} buttonWidth={2}></LinkButton>
                </Hidden>
              </Box>
            )
          }
        </>
      )
    } else {
      return (
        <>
          <Typography variant="body2">
            {loading ?
              <Skeleton
                animation="wave"
                variant="text"
                height={150} /> :
              data.section.description}
          </Typography>
          {loading ?
            null :
            data.section.linkTitle &&
            <Box className={classes.linkContainer}>
              <Hidden smDown>
                <LinkButton
                  title={data.section.linkTitle}
                  href={data.section.linkHref}
                  subtitle={data.section.linkSubtitle}
                  buttonSize={25}
                  buttonWidth={3}
                >
                </LinkButton>
              </Hidden>
              <Hidden mdUp>
                <LinkButton
                  title={data.section.linkTitle}
                  href={data.section.linkHref}
                  subtitle={data.section.linkSubtitle}
                  buttonSize={15}
                  buttonWidth={2}
                >
                </LinkButton>
              </Hidden>

            </Box>
          }
        </>
      )
    }
  }

  return (
    <Container component="section" id={loading ? null : data.section.pageId}>
      <Box className={classes.section} maxWidth={900}>
        <Grid container direction="row">
          <Grid item xs={12} md={6}>
            <Box className={classes.contentContainer} style={{ minHeight: minHeightVar }}>
              <Typography variant="h4">
                {loading ? <Skeleton animation="wave" variant="text" /> : data.section.title}
              </Typography>

              {loading ?
                <Skeleton
                  animation="wave"
                  variant="text" /> :
                <Box className={classes.shiftContainer}>
                  <ColorShift
                    variant={data.section.colorShiftVariant}>
                  </ColorShift>
                </Box>
              }
              <InnerContent data={data}></InnerContent>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {loading ? <Skeleton classes={{ root: classes.imgSkeleton }} animation="wave" variant="text" /> : <img alt={data.section.image.imageAltText} className={classes.image} src={data.section.image.url}></img>}
          </Grid>
        </Grid>
      </Box>
    </Container>

  )
}