import { makeStyles } from "@material-ui/core";
import { Triangle } from "../"

const useStyles = makeStyles(theme => ({
  circle: {
    border: `solid ${theme.palette.text.primary}`,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center"
  },
  triangle: {
    transform: "translateX(-5px)"
  }
}))

export default function CircleArrow({ size, width }) {

  const classes = useStyles()
  const triTransform = size * .2
  return (
    <div className={classes.circle} style={{ padding: size, borderWidth: width }}>
      <div className={classes.triangle} style={{ transform: `translate(-${triTransform}px` }}>
        <Triangle size={size} width={width}></Triangle>
      </div>
    </div>
  )
}