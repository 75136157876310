import { makeStyles } from "@material-ui/core";
import { Triangle } from "../";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "auto",
    margin: 0,
  }
}))

export default function TrippleArrow({ size, spacing = -20, width, ...rest }) {

  const classes = useStyles()

  return (
    <div className={classes.root}>
      {[...Array(3)].map((value, index) => (
        <div style={{ transform: `translateY(${spacing * index}px)` }} key={index}>
          <Triangle rotation={45} size={size} width={width}></Triangle>
        </div>
      ))}
    </div>
  )
}