import { Container, Grid, Typography, Box} from "@material-ui/core"
import { Nav, Footer } from "../../components/organism"


export default function NotFound() {
  return(
    <>
    <Nav></Nav>
    <Container>
      <Grid component="section" container>
        <Grid item xs={12}>
          <Box height="70vh" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography variant="h1">404</Typography>
            <Typography variant="body1" align="center">Leider nicht gefunden, aber es gibt auch andere schöne Ecken auf dieser Seite.</Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
    <Footer></Footer>
    </>
  )
}