import { Container, Typography, Grid, Box } from "@material-ui/core"

export default function NothingHere({ data }) {
  if (data.sections.length === 0) {
    return (
      <Container>
        <Grid component="section" container>
          <Grid item xs={12}>
            <Box height="50vh" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <Typography variant="h2">Schade 😢</Typography>
              <Typography variant="body1">Hier gibt es gerade nichts zu entdecken.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  } else {
    return (
      <>
      </>
    )
  }
}