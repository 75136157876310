import { makeStyles, Box } from "@material-ui/core";
import Lottie from 'react-lottie';
import animationData from './world.json';


const useStyles = makeStyles(theme => ({
  root: {}
}))

export default function World({size = 800}) {

  const classes = useStyles()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
      <Lottie
        options={defaultOptions}
        height={size}
        width={size}
      />

  )
}