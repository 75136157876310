import { CircleArrow } from "../../atoms";

import { ButtonBase, Box, Typography, makeStyles, Link as MuiLink } from "@material-ui/core";
import theme from "../../../theme";
import { Link as RouterLink } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "start",

  },
  link: {
    color: "inherit",
    fontFamily: "inherit",
    fontSize: "inherit",
    textDecoration: "inherit"
  }
}))

export default function LinkButton({ href, title, subtitle, expectedWidth = "auto", notRounded, buttonSize = 25, buttonWidth }) {

  const classes = useStyles()
  if (href && href[0] === "/") {
    return (
      <ButtonBase className={classes.root} style={{ width: expectedWidth, borderRadius: !notRounded && 30 }}>
        <RouterLink className={classes.link} to={href}>
          <Box display="flex" flexflow="row nowrap">
            <CircleArrow size={buttonSize} width={buttonWidth}></CircleArrow>
            <Box marginLeft={theme.spacing(.2)} textAlign="left" display="flex" justifyContent="center" flexDirection="column">
              <Typography variant="h5" fontWeight="300">{title}</Typography>
              {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
            </Box>
          </Box>
        </RouterLink>
      </ButtonBase>
    )
  } else {

    return (
      <ButtonBase className={classes.root} style={{ width: expectedWidth, borderRadius: !notRounded && 30 }}>
        <MuiLink href={href} underline="none" color="inherit" target="_blank" rel="noopener">
          <Box display="flex" flexflow="row nowrap">
            <CircleArrow size={buttonSize} width={buttonWidth}></CircleArrow>
            <Box marginLeft={theme.spacing(.2)} textAlign="left" display="flex" justifyContent="center" flexDirection="column">
              <Typography variant="h5" fontWeight="300">{title}</Typography>
              {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
            </Box>
          </Box>
        </MuiLink>
      </ButtonBase>

    );
  }

}